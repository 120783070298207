<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';
export default {
  name: 'Index',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'RequestsVerificationsReport',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Журнал проверки заявок КЦ',
        },
      ],
    };
  },
};
</script>
